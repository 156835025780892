import React from 'react';
import PropTypes from 'prop-types';

import withRoot from '../withRoot';
import Layout from '../components/Layout';
import IntroText from '../components/IntroText';
import Notes from '../components/Notes';
import DealHeadersRow from '../components/DealHeadersRow';
import Meta from '../components/Meta';
import PromptBoxes from '../components/PromptBoxes';
import TopicHeader from '../components/TopicHeader';

import EuRulesTopic from '../topics/eu-rules';
import UK2EUContributionTopic from '../topics/uk-eu-contribution';
import EU2UKContributionTopic from '../topics/eu-uk-contribution';
import VATTopic from '../topics/vat';
import SovereigntyTopic from '../topics/sovereignty';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Link = (({ href, children }) => (
  <OutboundLink
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </OutboundLink>
));

const Sovereignty = ({ classes, className, location }) => (
  <Layout location={location}>
    <Meta location={location} title="Sovereignty" shareImage="sovereignty" description="See how various Brexits affect sovereignty" />
    <IntroText />
    <TopicHeader title="Sovereignty" />
    <DealHeadersRow />
    <SovereigntyTopic />
    <EuRulesTopic />
    <UK2EUContributionTopic />
    <EU2UKContributionTopic />
    <VATTopic />
    <PromptBoxes/>
    <Notes/>
  </Layout>
);

Sovereignty.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
};

Sovereignty.defaultProps = {
  data: {},
};

export default withRoot(Sovereignty);
