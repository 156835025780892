import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as EuFundingIcon } from '../assets/eu-funding.svg';

const topic = {
  "title": "EU contribution to UK",
  "results": {
    "deal": {
      "status": "red",
      "comment": "The EU would no longer contribute to the UK"
    },
    "disorderly": {
      "status": "red",
      "comment": "The EU would no longer contribute to the UK"
    },
    "remain": {
      "status": "green",
      "comment": "The EU contributes to the UK in many ways"
    },
    "reform": {
      "status": "green",
      "comment": "The EU contributes to the UK in many ways"
    }
  }
};

const EU2UKContributionTopic = () => (
  <Topic topic={topic} icon={EuFundingIcon}>
    <p>
      The EU provides funds to many parts of the UK with The North of England,
      Scotland, Wales, Northern Ireland and the South West all receiving more
      per person than richer parts of the UK such as London. This helps to
      reduce regional wealth disparities. Without this, the UK could see an
      even greater imbalance between London and the rest of the country.
      Analysis by the University of Sheffield estimated that over 70,000 jobs
      were created in the North of England alone by these EU funds.
      <OutboundLink href="https://www.telegraph.co.uk/news/2016/06/01/mapped-where-in-the-uk-receives-most-eu-funding-and-how-does-thi/">(source)</OutboundLink>
    </p>
    <p>
      To see how the EU funds projects near you, visit
      <OutboundLink href="https://www.myeu.uk?utm_source=comparethebrexit&utm_campaign=comparethebrexit">https://www.myeu.uk</OutboundLink>.
    </p>
  </Topic>
);

export default EU2UKContributionTopic;
