import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as VATIcon } from '../assets/taxes.svg';

const topic = {
  "title": "5% minimum VAT",
  "results": {
    "deal": {
      "status": "green",
      "comment": "If we leave the EU we have the option to reduce VAT below 5%"
    },
    "disorderly": {
      "status": "green",
      "comment": "If we leave the EU we have the option to reduce VAT below 5%"
    },
    "remain": {
      "status": "amber",
      "comment": "We must charge a minimum of 5% VAT on certain items (note: this is changing)"
    },
    "reform": {
      "status": "green",
      "comment": "The 5% minimum VAT rate is being reformed"
    }
  }
};

const VATTopic = () => (
  <Topic topic={topic} icon={VATIcon}>
    <p>
      We currently have to keep a minimum of 5% VAT on certain items. If we
      leave the EU there is no guarantee that the 5% minimum tax on these
      items would be lowered, but it would become possible to do so. However,
      if we stay within the EU it will soon also become possible to do so as
      the rules are currently in the process of being reformed.
    </p>
    <p>
      Britain has been instrumental in making this change within the EU,
      leading the way for items such as baby clothes and tampons to have a
      tax rate lower than 5% in all EU countries (should the individual
      countries decide that they want to)
      <OutboundLink href="https://ec.europa.eu/taxation_customs/business/vat/action-plan-vat/proposal-vat-rates_en">(source)</OutboundLink>.
    </p>
  </Topic>
);

export default VATTopic;
