import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as SovereigntyIcon } from '../assets/pound-sterling.svg';

const topic = {
  "title": "Sovereignty",
  "results": {
    "deal": {
      "status": "amber",
      "comment": "Northern Ireland would still have to follow EU regulations"
    },
    "disorderly": {
      "status": "green",
      "comment": "Britain would not have to follow EU laws (even beneficial ones)"
    },
    "remain": {
      "status": "amber",
      "comment": "Britain has to follow some EU laws"
    },
    "reform": {
      "status": "green",
      "comment": "The EU promised more sovereignty if Britain chose to remain"
    }
  }
};

const SovereigntyTopic = () => (
  <Topic topic={topic} icon={SovereigntyIcon}>
    <p>
      Currently, Britain has to follow EU laws such as the Working Time
      Directive which gives workers the right to rest breaks, paid holidays
      and more.
      <OutboundLink href="https://en.wikipedia.org/wiki/Working_Time_Directive_2003">(more info)</OutboundLink>.
      However, Britain does not have to take part in everything the EU does
      such as using the Euro as currency. As it stands, we do still retain
      some sovereignty within the EU. If we leave, we will gain sovereignty
      in the sense that we will no longer have to follow EU laws such as the
      Working Time Directive, but our goods would still need to meet EU
      regulations if we wish to sell them within the EU. Because almost half
      of British exports are to the EU, this means we will still produce
      goods that conform to EU regulations on a large scale, even if we
      leave the EU.
    </p>
    <p>
      David Cameron negotiated a new deal within the EU to ensure that
      Britain would keep it's sovereignty while remaining part of the EU.
      This included a new commitment to exempt Britain from an "ever
      closer union" in new treaties. He also secured Britain a new
      "red-card" mechanism, similar to a veto. This gives parliament much
      greater power in Britain, and gives Britain much greater power
      within the EU.
      <OutboundLink href="https://www.bbc.co.uk/news/uk-politics-eu-referendum-35622105">(source)</OutboundLink>.
    </p>
    <p>
      No other country has managed to secure this type of deal within the
      EU. If Britain were to stay, other countries could follow Britain's
      lead and reform the EU into something that allows greater
      sovereignty for all.
    </p>
  </Topic>
);

export default SovereigntyTopic;
