import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as EuRulesIcon } from '../assets/eu-rules.svg';

const topic = {
  "title": "Influence EU rules",
  "results": {
    "deal": {
      "status": "red",
      "comment": "No ability to make, change, or block EU rules"
    },
    "disorderly": {
      "status": "red",
      "comment": "No ability to make, change, or block EU rules"
    },
    "remain": {
      "status": "green",
      "comment": "UK has a say in EU rules plus citizens benefit from EU laws protecting them"
    },
    "reform": {
      "status": "green",
      "comment": "UK has a say in EU rules plus citizens benefit from EU laws protecting them"
    }
  }
};

const EuRulesTopic = () => (
  <Topic topic={topic} icon={EuRulesIcon}>
    <p>
      In order to trade with the EU, countries must abide by their trade rules - even
      if they're not a member. The difference is that non-members don't have a say.
    </p>
    <p>
      The regulations Britain would no longer have to abide by include laws that protect
      consumers and workers such as the working time directive, which gives workers the
      right to a minimum number of holiday days and rest breaks.
    </p>
  </Topic>
);

export default EuRulesTopic;
