import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as EuFundingIcon } from '../assets/eu-funding.svg';

const topic = {
  "title": "UK contribution to EU",
  "results": {
    "deal": {
      "status": "blue",
      "comment": "UK may pay to participate in some EU programmes after Brexit"
    },
    "disorderly": {
      "status": "blue",
      "comment": "UK may pay to participate in some EU programmes after Brexit"
    },
    "remain": {
      "status": "green",
      "comment": "The UK makes an estimated 7bn net contribution to the EU but this is outweighed by the benefits of EU membership"
    },
    "reform": {
      "status": "green",
      "comment": "The UK makes an estimated 7bn net contribution to the EU but this is outweighed by the benefits of EU membership"
    }
  }
};

const UK2EUContributionTopic = () => (
  <Topic topic={topic} icon={EuFundingIcon}>
    <p>
      The net contribution varies year to year and can be worked out in different
      ways (for example sometimes EU contributions to private companies are
      excluded). It is sometimes worked out as low as 5.3bn or as high as 9bn.
      We have selected the figure of 7bn as it is an estimate somewhere in the
      middle.
    </p>
    <p>
      This figure is small in terms of our government's annual budget; net EU
      contributions make up less than 1% of government spending. It is less
      than 1/8th of defense spending and 1/4 of spending on the Department for
      Business, Innovation and Skills.
      <OutboundLink href="http://www.cbi.org.uk/insight-and-analysis/our-global-future/factsheets/factsheet-2-benefits-of-eu-membership-outweigh-costs/">(source)</OutboundLink>
    </p>
    <p>
      This figure of 7bn means that we have access to the economical benefits
      that EU membership includes. It has been estimated that if we leave the
      EU we will not save 7bn in fact we will be much worse off. In 2020 we are
      predicted to be 15bn worse off. This means we will have less money for
      the NHS, schools and other publically funded necessities.
      <OutboundLink href="https://fullfact.org/europe/does-brexit-dividend-exist/">(source)</OutboundLink>
    </p>
  </Topic>
);

export default UK2EUContributionTopic;
